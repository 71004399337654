<template>
  <!-- single bus info -->
  <b-card
    class="bus-card text-center"
    :style="{borderColor: getSuitableColor}"
  >

    <!-- icon and title -->
    <div>
      <feather-icon
        icon="TruckIcon"
        class="primary"
        :stroke="getSuitableColor"
        size="70"
      />

      <h3 class="title-header">
        {{ selectedBus.arabicName }}
      </h3>
    </div>

    <!-- bus properties -->
    <div class="mt-1">
      <span class="bus-property-title"> {{ $t('plateNumber') + ' : ' }}</span>
      <span> {{ selectedBus.plateNumber }}</span><br>

      <span class="bus-property-title"> {{ $t('driverName') + ' : ' }}</span>
      <span> {{ selectedBus.driverNameAr }} </span><br>

      <span class="bus-property-title"> {{ $t('busCapacity') + ' : ' }}</span>
      <span> {{ selectedBus.passengerCount }} </span><br>

      <span class="bus-property-title"> {{ $t('supervisor') + ' : ' }}</span>
      <span> {{ selectedBus.supervisorNameAr }} </span>
    </div>

    <!-- detail button -->
    <div class="mt-2">
      <b-button
        variant="none"
        :style="{color: getSuitableColor}"
        class="detail-btn text-nowrap"
        @click="
          () => {
            openPopupForSelectedBus();
          }
        "
      >
        <feather-icon
          class="ml-50"
          icon="InfoIcon"
        />
        {{ $t('showDetails') }}
      </b-button>
    </div>

    <!-- print button -->
    <div class="mt-50">
      <b-button
        variant="none"
        :style="{backgroundColor: getSuitableColor}"
        class="print-btn text-nowrap"
        @click="print()"
      >
        <feather-icon
          class="ml-50"
          icon="PrinterIcon"
        />
        {{ $t('print') }}
      </b-button>
    </div>

    <!-- popup menu -->
    <b-row>
      <b-modal
        ref="bus-model"
        no-close-on-backdrop
        cancel-variant="outline-secondary"
        centered
        size="lg"
        hide-footer
        :title="$t('busDetails')"
      >
        <!-- selected bus name and number -->
        <b-row>
          <b-col class="text-center">
            <h1 class="title-header mb-1">
              {{ selectedBus.arabicName }}
            </h1>
            <span class="mb-1 d-block"> {{ $t('busCapacity') }} <span class="bus-info "> {{ selectedBus.passengerCount }}</span> </span>
            <span>{{ $t('studentsCountRecordedInBus') }} </span> <span class="bus-info "> {{ selectedBus.studentBuses.length }}  </span>
          </b-col>
        </b-row>

        <!-- student selection -->
        <b-row>
          <b-col
            lg="4"
            md="6"
            sm="12"
            class="student-select"
          >
            <label
              for="student"
            >
              {{ $t('student') }}
              <feather-icon
                v-b-tooltip.hover="$t('studentselectioninBusHint')"
                icon="InfoIcon"
                class="mx-25 clickable"
              />
            </label>
            <g-field
              field="select"
              :label="isRight ? 'arabicName' : 'englishName'"
              name="student"
              :options="students"
              :dir="isRight ? 'rtl' : 'ltr'"
              :value.sync="newStudentId"
            />
          </b-col>

          <!-- add to list button -->
          <b-col>
            <b-button
              v-show="newStudentId"
              v-permission="$route.meta.permission"
              class="mx-1 add-to-list"
              variant="outline-adn"
              @click="addToList(newStudentId)"
            >
              <feather-icon
                class="mr-50"
                icon="UserPlusIcon"
              />
              {{ $t("addToStudentList") }}
            </b-button>
          </b-col>
        </b-row>

        <!-- students selected for current bus -->
        <g-table
          :items="gridItems"
          :columns="gridColumns"
          perPage="15"
          :createButton="{ visiable: false }"
          :excelButton="{ visiable: false }"
          :pdfButton="{ visiable: false }"
          :searchInput="{ visiable: false }"
        >
          <template #actions="{ item }">
            <div class="text-nowrap">
              <b-button
                v-permission="'execludeStudentFromBus'"
                variant="outline-adn"
                size="sm"
                @click="execludeStudentFromBus(item)"
              >
                {{ $t('exclude') }}
              </b-button>
            </div>
          </template>
        </g-table>

        <!-- buttons -->
        <b-row>
          <b-col
            cols="12"
            class="d-flex justify-content-end"
          >
            <!-- save button -->
            <b-button
              v-permission="$route.meta.permission"
              class="mx-1"
              data-action-type="save"
              variant="gradient-primary"
              @click="save()"
            >
              <feather-icon
                class="ml-50"
                icon="SaveIcon"
              />
              {{ $t("save") }}
            </b-button>
          </b-col>
        </b-row>
      </b-modal>
    </b-row>
  </b-card>
</template>

<script>
import { BCard } from 'bootstrap-vue'
import GTable from '@/pages/Shared/Table.vue';
import { busStatus } from '@/libs/acl/Lookups';
import reportMixin from '@/mixin/reportMixin';

export default {
  components: {
    BCard,
    GTable
  },

  mixins: [reportMixin],

  props: {
    selectedBus: {
      type: Object,
      default() { return {} }
    },

    students: {
      type: Array,
      default() { return [] }
    },

    allBuses: {
      type: Array,
      default() { return [] }
    }
  },

  data() {
    return {
      gridItems: [],
      accomodation: {
        status: null,
        busId: null,
        lines: []
      },
      newStudentId: 0,
    }
  },

  computed: {
    gridColumns() {
      return [
        {
          key: 'code',
          field: 'code',
          label: this.$t('code'),
          sortable: true,
        },
        {
          key: 'name',
          field: 'name',
          label: this.$t('studentName'),
          sortable: true,
        },
        {
          key: 'mobile',
          field: 'mobile',
          label: this.$t('mobile'),
          sortable: true,
        },
        {
          key: 'busLocation',
          field: 'busLocation',
          label: this.$t('detailedAddress'),
          sortable: true,
        },
        {
          key: 'actions',
          label: this.$t('actions'),
          sortable: false,
        },
      ];
    },

    getSuitableColor() {
      return busStatus.find((lokVal) => lokVal.id === this.selectedBus.status).color
    }
  },

  methods: {
    save() {
      // fill default data
      this.accomodation.busId = this.selectedBus.id;
      this.accomodation.lines = []

      // check for new students in accomodation list
      this.gridItems.forEach(element => {
        const isStudentExists = this.selectedBus.studentBuses.find((x) => x.studentId === element.id);
        if (!isStudentExists) {
          this.accomodation.lines.push({ studentId: element.id, state: 'added' })
        }
      });

      // check if any student in accomdation removed
      this.selectedBus.studentBuses.forEach(element => {
        const isStudentExists = this.gridItems.find((x) => x.id === element.studentId);
        if (!isStudentExists) {
          this.accomodation.lines.push({ studentId: element.studentId, state: 'removed' })
        }
      });

      if (this.gridItems.length > this.selectedBus.passengerCount) {
        this.doneAlert({ text: this.$t('busIsFull'), type: 'warning' });
        return;
      }

      // reset status based on current passengers
      if (this.gridItems.length === 0) {
        this.accomodation.status = 'empty'
      } else if (this.gridItems.length > 0 && this.gridItems.length < this.selectedBus.passengerCount) {
        this.accomodation.status = 'contain'
      } else if (this.gridItems.length === this.selectedBus.passengerCount) {
        this.accomodation.status = 'complete'
      } else {
        this.accomodation.status = ''
      }

      // then save
      this.update({
        url: 'Buses/ApplyBusAccomodation',
        data: this.accomodation
      })
      .then(() => {
        // show success message then hide pop-up menu and finally reset status
        this.doneAlert({ text: this.$t('savedSuccessfully') });
        this.$refs['bus-model'].hide();
        this.$emit('status-changed');
      });
    },

    async DelaySome(timeMs) {
      await new Promise((resolve) => setTimeout(resolve, timeMs))
    },

    openPopupForSelectedBus() {
      // if bus have students then show it
      this.gridItems = [];
      this.checkAndAddToGrid();

      // then show pop-up menu
      this.newStudentId = 0
      this.$refs['bus-model'].show();
    },

    getStudentsHaveBus() {
      this.get({ url: 'Students/getSubscribedStudentsInBus' }).then((data) => {
        this.students = data;
      });
    },

    checkAndAddToGrid() {
      if (this.selectedBus.studentBuses.length > 0) {
        this.selectedBus.studentBuses.forEach(element => {
        this.addToList(element.studentId);
      });
      }
    },

    findStudent(source, compareTo) {
      return source.find((val) => val.id === compareTo)
    },

    execludeStudentFromBus(item) {
      const itemIndex = this.gridItems.findIndex(val => val.id === item.id)
      this.gridItems.splice(itemIndex, 1)
    },

    addToList(studentId) {
      // if student is already added inform user
      if (this.findStudent(this.gridItems, this.newStudentId)) {
        this.doneAlert({ text: this.$t('studentAlreadyAddedToBusList'), type: 'warning' });
        return
      }

      // validate if student in another bus or not
      for (let i = 0; i < this.allBuses.length; i++) {
        if (!this.selectedBus.studentBuses.find((val) => val.studentId === this.newStudentId)
            && this.allBuses[i].studentBuses.find(x => x.studentId === this.newStudentId) !== undefined) {
          this.doneAlert({ text: `${this.$t('studentExistsInAnotherBus')} ${this.allBuses[i].arabicName}`, type: 'warning' });
          return
        }
      }

      // then add it to grid
      const selectedStudent = this.findStudent(this.students, studentId);
      const gridItem = {
        id: selectedStudent.id,
        code: selectedStudent.code,
        name: this.isRight ? selectedStudent.arabicName : selectedStudent.englishName,
        mobile: selectedStudent.mobileNumber,
        busLocation: selectedStudent.busLocation
      }
      this.gridItems.push(gridItem)
      this.newStudentId = 0;
    },

    validateBeforePrint() {
      if (this.selectedBus.studentBuses.length === 0 || this.gridItems.length === 0) {
        this.doneAlert({ text: this.$t('noStudentsInBus'), type: 'info' });
        return false
      }
      return true
    },

    print() {
      if (!this.validateBeforePrint()) return;
      const busToPrint = {
        id: this.selectedBus.id
      }
      this.printReport(this.isRight ? 'Bus-ar' : 'Bus-en', busToPrint);
    }
  }
}
</script>

<style>
.bus-property-title {
 color: #fc8282;
 font-size: 16px;
}

.title-header {
  letter-spacing: 1.25px;
}

.add-to-list {
  margin-top: 26px;
  margin-right: -15px !important;
}

.bus-info {
  border: 1px solid #c3bec6;
  border-radius: 30%;
  padding: 1px 15px;
  margin-left: 5px
}

.bus-card {
  border-top: 2px solid;
  border-bottom: 2px solid;
}

.bus-card button{
  width: 165px;
}

.bus-card .detail-btn {
  border: 1px solid;
}

.bus-card .print-btn {
  color: #FFF;
}

@media (max-width: 768px) {
  .add-to-list {
    margin-top: 0px;
    margin-bottom: 15px;
  }
}

.student-select label{
  font-size: 14px;
  margin-bottom: 7px;
}

@media (max-width: 768px) {
  .student-select label {
    margin-top: 20px;
  }
}
</style>
